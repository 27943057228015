html {
  font-size: 16px !important;
  color: white !important;
  background-color: #121212 !important;
  width: 100vw;
  height: 100vh;
}

main {
  overflow: unset !important;
}

/* label {
   color: #9e9e9e !important;
   font-size: 16px !important;
 }

input{
 font-size: 16px !important;
}

button{
  font-size: 16px !important;
} */

/* Global css */

.container-form {
  background-color: #232323;
  padding: 15px;
  border-radius: 8px;
  min-height: 600px;
}
.container-document {
  height: 93vh;
  overflow: auto;
  overflow-x: hidden;
}

.container-home {
  padding: 15px;
}

/* temporal */

/* .MuiStepLabel-label.MuiStepLabel-active {
   border-bottom: 1px solid #50C1EE;
   padding-bottom: 10px;
   padding-right: 50px;
   padding-left: 50px;
 } */

.bgtext:after {
  content: 'Background text';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.comunContainer {
  margin-top: 15px;
  margin-bottom: 15px;
  display: block;
}

.comunContainerFlex {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.comunContainerHide {
  margin-top: 15px;
  margin-bottom: 15px;
  display: none;
}

.centerContainer {
  width: 100%;
  text-align: center;
}

/* Global css */

.card .card-action:last-child {
  border-radius: 10px !important;
}

.card-blue {
  background-color: #16b1f3;
  border-radius: 10px !important;
  font-size: 15px;
}

.card-blue-title {
  padding-left: 10px !important;
  padding-top: 30px !important;
}

.card-blue-content {
  padding: 25px 10px 5px 25px !important;
}

.card-gray {
  background-color: #2e2e2e !important;
  border-radius: 10px !important;
}

.label-big {
  font-size: 40px !important;
}

.btn-yellow {
  background-color: #f5d13d !important;
  width: 100%;
  height: 50px;
  font-weight: bold;
  border-radius: 15px;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.icon-shadow {
  border: 4px;
}

/* Begin scroll */

/* width */

::-webkit-scrollbar {
  width: 8px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track {
  background: #232323;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 5px;
  height: 10px !important;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* End scroll */

/* Begin Stepper My Account */

.stepper-title {
  font-size: 16px !important;
  padding-bottom: 10px;
  color: white;
}

/* End Stepper  My Account * */

.title-yellow {
  height: 25px;
  border-radius: 5px;
  background-color: #f5d13d;
  color: #2c2c2c;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  text-align: center;
}

.title-users {
  width: 98%;
  margin-right: 10px;
}

.note-yellow {
  text-align: justify;
  margin-left: 2rem;
  margin-right: 2rem;
  color: #f5d13d;
  font-size: 15px;
  font-style: italic;
}

.custom-collapsible {
  border-radius: 5px !important;
  border: none !important;
}

.custom-collapsible-header {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  background-color: rgba(255, 255, 255, 0.18) !important;
  border: none !important;
  color: #ffffff !important;
  font-size: 16px !important;
}

.custom-collapsible-body {
  border: none !important;
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
  background-color: rgba(255, 255, 255, 0.18) !important;
}

.btn-primary {
  border-radius: 5px;
  background-color: #16b1f3;
  width: 100%;
  height: 50px;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.btn-invoice {
  border-radius: 5px;
  background-color: #16b1f3;
  width: 50%;
  height: 50px;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.container-title {
  width: 100%;
  border-radius: 5px;
  background-color: rgba(18, 18, 18, 0.3);
  color: #2cc63e;
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* .card-columns-user {
} */

/*Estilo switch */

.switch label .inputCheck[type='checkbox']:checked + .lever {
  background-color: #9ff781;
}

.switch label .inputCheck[type='checkbox']:checked + .lever:after {
  background-color: #40ff00;
}

.switch label .lever {
  display: inline-block;
  position: relative;
  width: 36px;
  height: 14px;
  background-color: rgba(69, 90, 100, 0.4);
  border-radius: 15px;
  margin-right: 10px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  vertical-align: middle;
  margin: 0 16px;
}

.switch label .lever:after {
  background-color: #d8d8d8;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.inputCheck[type='checkbox']:checked:not(:disabled) ~ .lever:active::before,
.inputCheck[type='checkbox']:checked:not(:disabled).tabbed:focus
  ~ .lever::before {
  -webkit-transform: scale(2.4);
  transform: scale(2.4);
  background-color: rgba(206, 147, 216, 0.4);
}

/*Estilo Circle de iconos*/

.circle {
  border-radius: 50%;
  background: #82fa58;
  color: black;
}

/*Estilos para MyAccount*/

.toolip-help {
  color: #2dbf3e;
}

.scrollPanel {
  width: 100%;
  border: none;
  overflow: auto;
  position: relative;
  max-height: 1000px;
  border-radius: 5px;
  background-color: transparent !important;
  margin-right: 10px;
}

.custom-collapse {
  background-color: rgba(255, 255, 255, 0.18);
}

/*CAMBIO FERNANDO RUIZ TOAST STYLE*/

/* Begin styles Toast */

.ct-toast {
  background-color: rgba(95, 93, 93, 0.83) !important;
  color: white !important;
  width: 70% !important;
  justify-content: left !important;
}

/* End styles Toast */

.swal2-container {
  z-index: 99999 !important;
}

.swal2-popup {
  background-color: #363636 !important;
  color: white !important;
}

.swal2-content {
  color: white !important;
}

.swal2-title {
  color: white !important;
}

.swal2-styled.swal2-cancel {
  background-color: #363636 !important;
  font-size: 100 !important;
  color: #7dd1f8 !important;
  border: none !important;
  outline: none !important;
}

.swal2-styled.swal2-confirm {
  background-color: #363636 !important;
  font-size: 100 !important;
  color: #7dd1f8 !important;
  border: none !important;
  outline: none !important;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

.modal {
  background-color: #a2e3a4;
  color: #272727;
  position: relative;
  border-radius: 5px;
  text-align: center;
  height: 40px;
}

@media screen and (max-width: 320px) {
  body {
    font: 10px;
  }
}

@media screen and (max-width: 1204px) {
  body {
    font: 12px;
  }
}

.swal2-popup .custom-swal-confirm-button {
  background-color: #2cc63e !important;
  color: white !important;
  width: 100%;
  height: 50px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.swal2-popup .custom-swal-confirm-button:hover {
  background-color: #388e3c !important;
}

.swal2-popup .custom-swal-cancel-button {
  background-color: #605d5d !important;
  color: #d8d8d8 !important;
  width: 100%;
  height: 50px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.swal2-popup .custom-swal-cancel-button:hover {
  background-color: #8a8585 !important;
}
